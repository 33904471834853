import React, { useEffect, useState, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { Table, Ordering, Paginator, Column, Subtitle, Tag } from '@duckma/react-ds'

import { api } from '../../data/api'
import { useRequest } from '../../hooks/useRequest'
import { User } from '../../data/models'
import { SkeletonTable } from '../../components/SkeletonTable'
import { dateTimeFormatter } from '../../utils/formatters'
import { TableActions } from '../../components/TableActions'
import { useHistory } from 'react-router-dom'

export const UsersPage = () => {
  const [getUsers, { status, data }] = useRequest(api.getUsers)
  const [deleteUser, { status: deleteStatus, data: deleteData }] = useRequest(api.deleteUser)
  const history = useHistory()
  const [ordering, setOrdering] = useState<Ordering<User>>({
    key: 'id',
    direction: 'asc',
  })
  const [page, setPage] = useState<number>(0)

  const getUsersWithParams = useCallback(
    () =>
      getUsers({
        order_by: ordering.key,
        order_direction: ordering.direction,
        $skip: page * 10,
        $limit: 10,
      }),
    [getUsers, ordering, page]
  )

  useMemo(() => {
    if(deleteStatus === 'success') {
      window.location.reload();
    }
  }, [deleteStatus])

  useEffect(() => {
    getUsersWithParams()
  }, [getUsersWithParams])

  const columns = useMemo(
    () =>
      [
        {
          key: 'actions',
          name: 'Actions',
          render: ({ id }) => (
            <TableActions
            onEdit={() => history.push(`/users/${id}`)}
            onDelete={() => {
              if(window.confirm(`Are you sure you want to delete user with id ${id}?`)){
                deleteUser({ id })
              }
            }}
          ></TableActions>
          ),
        },
        { key: 'id', name: 'ID', orderable: true },
        { key: 'email', name: 'Email', orderable: true },
        {
          key: 'role',
          name: 'Role',
          orderable: true,
          render: ({ roles }) => (
            <Tag
              text={roles.admin ? 'Admin' : 'User'}
              color={roles.admin ? 'success100' : 'primary100'}
            />
          ),
        },
        {
          key: 'createdAt',
          name: 'Creation Date',
          orderable: true,
          render: ({ createdAt }) => dateTimeFormatter(createdAt),
        },
        {
          key: 'updatedAt',
          name: 'Last Updated Date',
          orderable: true,
          render: ({ updatedAt }) => dateTimeFormatter(updatedAt),
        },
      ] as Column<User>[],
    []
  )

  return (
    <Container>
      {status !== 'loading' ? (
        <Table
          columns={columns}
          records={data ? data.data : []}
          order={ordering}
          onOrder={setOrdering}
        />
      ) : (
        <SkeletonTable />
      )}
      {status === 'success' && data && data.data.length > 0 && (
        <Paginator
          style={{ marginTop: '75px', marginBottom: '75px' }}
          currentPage={page}
          totalPages={Math.ceil(data.total / data.limit)}
          onPageSelect={setPage}
        />
      )}
      {status === 'success' && data && data.data.length === 0 && (
        <Subtitle text="No users found" color="gray100" />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 50px;
  padding-right: 50px;
  table {
    th {
      user-select: none;
    }
  }
`
