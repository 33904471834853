/**
 * This file defines various form validator of general use.
 * Single use functions shouldn't be put here.
 *
 * Each export is a higher order function that takes various configurations as input,
 * and returns a ready-to-use validator.
 */
export type Validator = (value: string, fields: { [fieldName: string]: string }) => string | null

export const requiredValidator: (msg?: string) => Validator = (msg = 'Required field') =>
  lengthValidator(1, undefined, msg)

export const lengthValidator: (
  min: number,
  max?: number,
  minMsg?: string,
  maxMsg?: string
) => Validator = (
  min,
  max = Number.MAX_VALUE,
  minMsg = `Mininum length is of ${min} characters`,
  maxMsg = `Maximum length is of ${max} characters`
) => (s: string) => (s.length < min ? minMsg : Array.from(s).length > max ? maxMsg : null)

var emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const emailValidator: (msg?: string) => Validator = (msg = 'Invalid email') => (email) =>
  emailRegex.test(email.toLowerCase()) ? null : msg

export const requiredHtmlValidator: (msg?: string) => Validator = (msg = 'Required field') => (
  description
) => (description.replace(/<(.|\n)*?>/g, '').trim().length === 0 ? msg : null)
