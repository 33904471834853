import { Media, User } from './models'
import { storage } from './storage'
import _ from 'lodash'

class API {
  private request = async <T>(
    method: string,
    path: string,
    body?: {},
    params?: { [key: string]: string | string[] }
  ) => {
    let uri = process.env.REACT_APP_BASE_URL + path
    if (_.keys(params).length > 0) {
      uri +=
        '?' +
        _.map(params, (value, key) =>
          Array.isArray(value) ? value.map((v) => `${key}=${v}`).join('&') : `${key}=${value}`
        ).join('&')
    }
    return fetch(uri, {
      method,
      body: body ? JSON.stringify(body) : undefined,
      headers: this.getHeaders(),
      credentials: 'include',
    }).then((res) => this.handleResponse<T>(res))
  }

  private handleResponse = <T>(response: Response) => {
    if (response.status !== 200) {
      console.error(`Error while sending request. Code: ${response.status}`)
    }
    if (response.status === 404) {
      throw new Error('login_wrong_credentials')
    }
    if (response.status === 401 || response.status === 403) {
      this.clearSession()
      window.location.reload()
    }
    if (response.headers.get('Content-Type')?.startsWith('text')) {
      return (response.text() as unknown) as T
    }
    return response.json().then((json) => {
      if (response.status !== 200) {
        throw new Error(json)
      }
      return json as T
    })
  }

  private getHeaders = () => {
    return {
      'Content-Type': 'application/json',
      Authorization: process.env.REACT_APP_AUTHORIZATION ?? '',
    }
  }

  private get = async <T>(
    path: string,
    params: { [key: string]: string | string[] },
    useToken: boolean = true
  ) => this.request<T>('GET', path, undefined, params)

  private delete = async <T>(
    path: string,
    params: { [key: string]: string },
    useToken: boolean = true
  ) => this.request<T>('DELETE', path, undefined, params)

  private post = async <T>(path: string, body: {}, useToken: boolean = true) =>
    this.request<T>('POST', path, body, undefined)

  private put = async <T>(path: string, body: {}, useToken: boolean = true) =>
    this.request<T>('PUT', path, body, undefined)

  private patch = async <T>(path: string, body: {}, useToken: boolean = true) =>
    this.request<T>('PATCH', path, body, undefined)

  private cleanParams = (params: {
    [key: string]: string | number | null | undefined | string[]
  }) =>
    _(params)
      .pickBy((value) => value !== null && value !== undefined && value !== '')
      .mapValues((param) => {
        if (Array.isArray(param)) {
          return param.map((p) => String(p))
        }
        return String(param)
      })
      .value()

  private persistSession = (res: LoginResponse) => {
    storage.saveToken(res.token)
    storage.saveUser(res)
    return res
  }

  public clearSession = () => {
    storage.deleteToken()
    storage.deleteUser()
  }

  public login = ({ email, password }: { email: string; password: string }) =>
    this.post<LoginResponse>('/users/login', { email, password }, false)
      .then((data) => {
        if (!data.roles.admin) {
          throw new Error('insufficient_role')
        }
        return data
      })
      .then(this.persistSession)

  public recoverPassword = ({ email }: { email: string }) =>
    this.post<string>('/users/password/reset', { email, locale: 'en' }, false)

  public updatePassword = ({
    password,
    session,
  }: {
    password: string
    session?: LoginResponse
  }) => {
    if (session) {
      // save this one in localStorage before requesting
      this.persistSession(session)
    }
    return this.post<User>('/users/profile', { password }).catch((err) => {
      if (session) {
        this.clearSession()
      }
      throw err
    })
  }

  public getMe = () => this.get<User>('/users/profile', {})

  public updateMe = (body: UpdateUser) => this.post<User>('/users/profile', body)

  private mapOrderBys = (orderBy: string) =>
    orderBy === 'role' ? 'roles.admin' : orderBy === 'id' ? '_id' : orderBy

  public deleteUser = (params: { id: string }) => this.delete<void>(`/users/${params.id}`, {})
  public getUsers = (params: ListParams) =>
    this.get<PaginatedResponse<User>>(
      '/users/search',
      this.cleanParams({
        ..._.omit(params, ['order_by', 'order_direction']),
        [`$sort[${this.mapOrderBys(params.order_by)}]`]: params.order_direction === 'asc' ? 1 : -1,
      })
    )
  public getUser = (params: { id: string }) => this.get<User>(`/users/${params.id}`, {})
  public getMedia = (params: { id: string }) =>
    this.get<PaginatedResponse<Media>>(`/users/${params.id}/media`, {})
}

export type LoginResponse = User & { token: string }

type PaginatedResponse<T> = {
  data: T[]
  limit: number
  skip: number
  total: number
}

type ListParams = {
  // search_text?: string
  $skip?: number
  $limit?: number
  order_direction: 'asc' | 'desc'
  order_by: string
}

export type UpdateUser = Pick<User, 'firstName' | 'lastName'>

export const api = new API()
