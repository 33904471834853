import React from 'react'
import { ThemeContext } from '@duckma/react-ds'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import { theme } from './styles/theme'
import { LoginPage } from './modules/auth/Login'
import './styles/global.css'
import { storage } from './data/storage'
import { UsersPage } from './modules/users/Users'
import { PasswordReset } from './modules/auth/PasswordReset'
import { PasswordOTP } from './modules/auth/PasswordOTP'
import { UpdatePassword } from './modules/auth/UpdatePassword'
import { MainLayout } from './components/MainLayout'
import { ProfilePage } from './modules/profile/Profile'
import { AppContextProvider } from './components/AppContext'
import { UserDetailsPage } from './modules/users/UserDetails'

const PrivateRoute = ({
  component: Component,
  header,
  ...rest
}: {
  component: React.ReactType
  header: React.ComponentProps<typeof MainLayout>
  [key: string]: any
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        storage.getToken() !== null ? (
          <MainLayout {...header}>
            <Component {...props} />
          </MainLayout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  )
}

function App() {
  return (
    <ThemeContext.Provider value={theme}>
      <AppContextProvider>
        <Router>
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/password-reset">
              <PasswordReset />
            </Route>
            <Route path="/password-otp">
              <PasswordOTP />
            </Route>
            <Route path="/update-password">
              <UpdatePassword />
            </Route>
            <Route exact path="/">
              <Redirect to="/users" />
            </Route>
            <PrivateRoute
              path="/users/:id"
              component={UserDetailsPage}
              header={{
                title: 'User Detail',
              }}
            />
            <PrivateRoute
              path="/users"
              component={UsersPage}
              header={{
                title: 'Users',
              }}
            />
            <PrivateRoute
              path="/me"
              component={ProfilePage}
              header={{
                title: 'Profile',
              }}
            />
          </Switch>
        </Router>
      </AppContextProvider>
    </ThemeContext.Provider>
  )
}

export default App
