import React, { useContext, useState, useMemo } from 'react'

export type ChatState = 'idle' | 'connecting' | 'connected' | 'disconnecting' | 'disconnected'
export type IAppContext = { chatStatus: ChatState; setChatStatus: (chatState: ChatState) => void }

const defaultValue: IAppContext = { chatStatus: 'idle', setChatStatus: () => {} }
const AppContext = React.createContext<IAppContext>(defaultValue)

export const AppContextProvider: React.FC<{}> = ({ children }) => {
  const [chatStatus, setChatStatus] = useState<ChatState>('idle')

  const value = useMemo(
    () => ({
      chatStatus,
      setChatStatus,
    }),
    [chatStatus, setChatStatus]
  )

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export const useAppContext = () => useContext(AppContext)
