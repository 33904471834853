type Languages = 'it'

export const errors: { [key: string]: { [key in Languages]: string } } = {
  server_error: {
    it: 'Server error. Retry later.',
  },
  invalid_server_response: {
    it: 'Invalid server response.',
  },
  login_wrong_credentials: {
    it: 'Invalid credentials.',
  },
  forbidden: {
    it: 'Not enough permissions.',
  },
  insufficient_role: {
    it: "You can't access the console.",
  },
  login_wrong_otp_credentials: {
    it: 'Wrong code.',
  },
}
