import React from 'react'
import styled from 'styled-components'
import { Button } from '@duckma/react-ds'

type Props = {
  onEdit?: () => void
  onDelete?: () => void
  additionalButtons?: React.ReactNode
  confirmDeleteMessage?: string
}
export const TableActions = ({
  onEdit,
  onDelete,
  additionalButtons,
  confirmDeleteMessage,
}: Props) => (
  <Actions>
    {additionalButtons}
    <Button size="icon" iconLeft="pen-alt" color="primary" radius={24} onClick={onEdit} />
    {onDelete && (
      <Button
        size="icon"
        iconLeft="trash"
        color="danger"
        radius={24}
        onClick={() => {
          if (!onDelete || (confirmDeleteMessage && !window.confirm(confirmDeleteMessage))) {
            return
          }
          onDelete()
        }}
      />
    )}
  </Actions>
)

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  * {
    margin-right: 20px;
  }
`
