import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { Button, Input, Title, Subtitle } from '@duckma/react-ds'
import { useRequest } from '../../hooks/useRequest'
import { api } from '../../data/api'
import { Redirect, useLocation, useHistory } from 'react-router-dom'
import { Link } from './components/Link'

export const PasswordOTP = () => {
  const { state } = useLocation<{ email?: string }>()
  const history = useHistory()
  const [code, setCode] = useState('')
  const [request, { status, data }] = useRequest(api.login)

  useEffect(() => {
    if (!state ?? !state.email) {
      history.push('/login')
    }
  }, [state, history])

  return (
    <Container
      onSubmit={(e) => {
        e.preventDefault()
        request({ email: state.email!, password: code })
      }}
    >
      {data && <Redirect to={{ pathname: '/update-password', state: { session: data } }} />}
      <Title text="Password Recovery" style={{ marginBottom: '33px' }} />
      <Subtitle text="Insert code you received via email" style={{ marginBottom: '84px' }} />
      <Input
        name="code"
        type="text"
        label=""
        placeholder="Insert code"
        size="large"
        value={code}
        onChange={setCode}
        style={{ marginBottom: '50px' }}
      />
      <Link to="/login" text="Back to login" />
      <Button
        text="Next"
        radius={4}
        loading={status === 'loading'}
        style={{ width: '50%', marginTop: '30px' }}
      />
    </Container>
  )
}

const Container = styled.form`
  width: 40%;
  padding-left: 30%;
  padding-right: 30%;
  padding-top: 20vh;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`
