import React, { useEffect, useState, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { Table, Ordering, Paginator, Column, Subtitle, Tag, Label } from '@duckma/react-ds'

import { api } from '../../data/api'
import { useRequest } from '../../hooks/useRequest'
import { User, Media } from '../../data/models'
import { SkeletonTable } from '../../components/SkeletonTable'
import { dateTimeFormatter } from '../../utils/formatters'
import _ from 'lodash'
import { ControlledForm, ControlledField } from '../../components/ControlledForm'
import { useParams } from 'react-router-dom'

type PaginatedResponse<T> = {
  data: T[]
  limit: number
  skip: number
  total: number
}

export const UserDetailsPage = () => {
  const { id } = useParams<{ id: string }>()
  const [getUser, { status, data: datas }] = useRequest(api.getUser)
  const [getMedia, { status: mediaStatus, data: mediaDatas }] = useRequest(api.getMedia)

  const [ordering, setOrdering] = useState<Ordering<Media>>({
    key: 'created_at',
    direction: 'asc',
  })
  const [page, setPage] = useState<number>(0)
  const dataObject = useMemo(
    () =>
      (_.pick(datas, ['email', 'id']) as {
        email: string
        firstName: string
        lastName: string
      }) ?? null,
    [datas]
  )

  const getMediaWithParams = useCallback(
    () => getMedia({ id }),
    [getMedia, id]
  )
  
  useEffect(() => {
    getMediaWithParams()
  }, [getMediaWithParams])

  useEffect(() => {
    getUser({ id })
  }, [getUser, id])

  const columns = useMemo(
    () =>
      [
        {
          key: 'created_at',
          name: 'Date',
          orderable: true,
          render: ({ created_at }) => dateTimeFormatter(created_at),
        },
        {
          key: 'url',
          orderable: false,
          render: (data) => (
            <a href={`${process.env.REACT_APP_BASE_URL}/users/${id}/media/${data.id}`} target="_balck" download>
              <ImageContainer url={`${process.env.REACT_APP_BASE_URL}/users/${id}/media/${data.id}`} />
            </a>
          ),
        },
      ] as Column<Media>[],
    []
  )

  return (
    <Container>
      <UserInfoContainer>
        <ControlledForm initialValues={datas ? dataObject : null}>
          <ControlledField name="email-address" fieldName="email" disabled />
          <ControlledField name="id" fieldName="id" disabled />
        </ControlledForm>
      </UserInfoContainer>
      <TableContainer>
        {status !== 'loading' && mediaDatas?.data ? (
          <Table
            columns={columns}
            records={mediaDatas.data || []}
            order={ordering}
            onOrder={setOrdering}
          />
        ) : (
          <SkeletonTable />
        )}
        {status === 'success' && mediaDatas?.data && mediaDatas.data.length === 0 && (
          <Subtitle text="No images found" color="gray100" />
        )}
      </TableContainer>
      {mediaDatas && mediaDatas.data.length > 0 && (
        <Paginator
          style={{ marginTop: '75px', marginBottom: '75px' }}
          currentPage={page}
          totalPages={Math.ceil(mediaDatas.total / mediaDatas.limit)}
          onPageSelect={setPage}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100%);
`

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 150px;
  width: calc(100% - 300px);

  table {
    th {
      user-select: none;
    }
  }
`
const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 300px);
  flex-wrap: wrap;
  justify-content: space-between;
  > * {
    flex-basis: 45%;
    margin-bottom: 20px;
  }
`
const ImageContainer = styled.div<{ url: string }>`
  width:200px;
  height:200px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("${(props) => props.url}");

`
